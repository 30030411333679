import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="221120 - Advent calendar" />
      <h2 className="font-title-sub text-center">Advent calendar</h2>
      <div className="text-center pb-4">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/photo_221120.jpg"
          width={500}
          quality={95}
        />
      </div>
      <p>Christmas is coming in the next month. It’s fun to walk around the streets decorated with Christmas ornaments, which also reminds me of Christmas markets in Europe (I can find some Christmas market in Japan too maybe? I really miss that atmosphere!)</p>
      <p>Recently I found many kinds of advent calendars are on sale at KALDI, and bought two, one for me and the another one for my sister. Mine is made in Czech, and the heartwarming package illustration is drawn by Josef Lada, famous Czech illustrator. There’re chocolates inside the box. I’m really looking forward to the coming month and starting countdown for Christmas with this calendar!</p>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
